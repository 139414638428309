/**
*
* Sass Import's
*
* Import all the partials to build up the final
* compiled stylesheet
*
* @param  {partial name: string}
*
* http://sass-lang.com/documentation/file.SASS_REFERENCE.html#import
*
* Sass extends the CSS @import rule to allow it to import SCSS and Sass files. All imported SCSS and
* Sass files will be merged together into a single CSS output file. In addition, any variables or mixins defined in
* imported files can be used in the main file.
*
*/

@import '../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import 'color-theme';
@import 'fonts';
@import 'buttons';
@import 'header';
@import 'footer';

// ==========================================================================
// Tweaks & Helpers
// ==========================================================================
.container {
	max-width: 980px;
}

body,
html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Lato', sans-serif;
	background: rgb(240, 81, 57);

}
body{
	font-family:"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
header{
	background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 90%;
    background-position-x: -160px;
}
section{
	background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 90%;
    background-position-x: -160px;
    font-family:"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.content {
	
	padding: 60px 0px;
	h1 {
		font-family: 'open sans';
		font-size: 45px;

		span {
			color: white;
			display: block;
			font-weight: bold;
			font-family: "source sans pro", Helvetica Neue, Helvetica, Arial, sans-serif;
			font-size: 50px;

		}
	}
	p,
	ul,
	li {
		font-size: 18px;
		line-height: 1.2;
		font-family:"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	}
	ul{
	margin-left:-20px;
	}
}	

.nowrap {
	white-space: nowrap;
}

.add-space {
	clear: both;
	width: 100%;
	height: 30px;
	&.half {
		height: 15px;
	}
}

.light-bg {
	background: rgb(240, 81, 57);
	padding: 60px 30px;
}

.dark-bg {
	background: rgb(0, 0, 0);
	padding: 60px 30px;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.third-vh {
	height: 30vh;
}

.text-center {
	text-align: center;
}

.absolute {
	position: absolute;
}

.relative {
	position: relative;
}

.book{
	max-width:60%;
	height:auto;
	position: absolute;
	margin-right:0px;
	float: right;
}
.computer{
	max-width:40%;
	height:auto;
	position: relative;
	margin-right:0px;
	float: right;


}

// ==========================================================================
// Buttons
// ==========================================================================
.mustard-btn {
	background: rgb(0,0,0);
	box-shadow: 0 6px darken(rgb(240, 81, 57), 20%);
	transition: none;
	padding: 14px 0px;
	color: rgb(255,255,255);
	border-radius: 3px;
	transition: all .34s ease-in;
	&:hover {
		background: rgb(0,0,0);
		box-shadow: 0 6px darken(rgb(240, 81, 57), 20%);
		color: rgb(255,255,255);
		text-decoration: none;
		border: 1px solid rgb(240, 81, 57);
	}
	a {
		color: rgb(255,255,255);
		display: block;
	}
	a:hover {
		text-decoration: none;
	}
}


// ==========================================================================
// Forms
// ==========================================================================
.formC p, h2{
	color:rgb(240,81,57);
	font-weight:bold;
}

input[type="email"],
input[type="tel"],
input[type="text"],
input[type="url"] {
	line-height: normal;
	font-size: 14px;
	border-radius: 3px;
	border: none;
	min-height: 40px;
	width: 100%;
	margin-bottom: 12px;
	padding-left: 16px;
	height: 50px;
	color: rgb(33, 30, 30);
	outline: none;
	transition: all 0.10s ease-in-out;
}

input[type="submit"] {
	margin-top: 10px;
	width: 100%;
	border-radius: 1px;
	background-color: rgb(240,81,57);
	outline: none;
	border: none;
	color: white;
	font-size: 24px;
	padding: 10px;
	color: white;
	font-weight: bold;
	font-family:"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

input:focus {
	box-shadow: 0 0 10px rgb(255,255,255);
	border: 1px solid rgb(255,255,255);
	color: rgb(135,135,135);
}

input::-webkit-input-placeholder{
	color: rgb(135,135,135);
}
//input::-moz-placeholder{
//	color: $lightgrey;
//}
//
//input:-ms-input-placeholder{
//	color: $lightgrey;
//}
//
//input:-moz-placeholder{
//	color: $lightgrey;
//}

.form-message{
	display:none;
	text-align:center;
	color: grey !important;
	h2{
		padding-top:75%;
	}
}



// ==========================================================================
// Footer
// ==========================================================================
footer {
	background-color: rgb(36,38,45);
	color: rgb(255,255,255);
	padding: 60px 0px;
	font-family:"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	h3 {
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 15px;
	}
	p {
		font-size: 18px;
		line-height: 1.2;
	}
	ul {
		list-style: none;
		padding: 0;
	}
	li {
		display: inline-block;
	}
	li:not(:last-of-type) {
		margin-right: 20px;
	}
	.newsMessage{
		display:none;
		color:rgb(240,81,57);
		font-weight:bold;	}
	.newsletter{
		display:block;
	}
}

// ==========================================================================
// Media Queries
// ==========================================================================
@media (min-width: 992px) {
	.img-container img {
		padding-top: 200px;
	}
}

.blackBox {
		background-color: black; 
		bottom: -70px; padding: 
		40px; padding-top:5px ; 
		min-height: 550px;
		margin-top: 30%;
	}

@media (max-width: 991px) {
	.relative {
		position: initial;
	}
}
