.btn {
    border: none;
    cursor: pointer;
    padding: 15px 30px;
    display: inline-block;
    border-radius: 3px 3px 5px 5px;
    letter-spacing: 1px;
    position: relative;
    transition: all 0.3s;
    font-size:18px;
    font-family: 'Proxima Nova Bold';
    color: getColor('white');
    &.btn-green {
        background: getColor('green');
        box-shadow: 0 6px getColor('green-is-active');
        &:hover,
        &:active,
        &:focus {
            box-shadow: 0 6px getColor('green');
            background:0 6px getColor('green-is-active');
            color:getColor('white');
            text-decoration: none;
        }
    }
}