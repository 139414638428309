/*========================================================================
// CERTIFICATIONS MODULE
==========================================================================*/

.certification {
    background: getColor('primary');
    padding: 30px 0;
    .wrapper--flex {
        display: flex;
        padding: 0 10%;
    }
    img {
        width: 100%;
        height: 100%;
    }
}

.copyright {
    background: #02457d;
    padding: 10px 0;
    font-family: 'Proxima Nova SemiBold';
    .wrapper--flex {
        display: flex;
        color: getColor('white');
        padding: 0 10%;
        display: flex;
        flex-direction: row;
        flex-grow: 1 1 50%;
        align-items: center;
        h5 {
            margin: 0;
            font-size: 14px;
            letter-spacing: .2px;
        }
        ul {
            margin-left: auto;
            margin-bottom: 0;
            padding: 0;
            font-size: 14px;
            li {
                font-family: 'Proxima Nova SemiBold';
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                &:nth-child(2) {
                    font-family: 'Proxima Nova Th';
                }
                &:last-child {
                    margin: 0;
                }
            }
        }
        a {
            color: getColor('white');
            transition: color .3s ease-out;
            &:hover {
                text-decoration: none;
                color: getColor('blue');
            }
        }
    }
}

@media only screen and (max-width:560px) {
    .copyright {
        padding: 25px 0;
    }
    .copyright .wrapper--flex {
        flex-direction: column;
    }
    .copyright .wrapper--flex ul {
        margin-left: inherit;
        margin-top: 5px;
    }
}