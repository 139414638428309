/**
* Array of oficial colors for SherWeb 2017 pallete
* @param  {key}     [key: string]
* @param  {hex}     [Hex: number]
*
*/

$theme-color: (
  'primary':           #025091,
  'primary-is-active': #023866,
  'secondary':         #6fa8db,
  'blue':              #31a5de,
  'blue-is-active':    #267fab,
  'bg-dark':           #dae4ef,
  'bg-light':          #e4ecf4,
  'black':             #0b0b0b,
  'grey':              #333333,
  'light-grey':        #d4d4d4,
  'white':             #ffffff,
  'confirm':           #59cc2f,
  'green':             #6ac212,
  'green-is-active':   #4e8f0d,
  'red-is-active':     #c00d0d,
  'alert':             #ff001e,
  'red':               #e30613,
  'warning':           #ffb413,
  'debug':             crimson
);


/**
* Function To Get Color Pallete
* @param  {key}          [string]
* @return {hex color}    [return paired hex color]
*
* @usage
* background: getColor('primary') => compile => background: #025091;
*
*/

@function getColor($key) {
  @return map-get($theme-color, $key);
}